import React, { createContext, useContext, useState } from 'react';

const AppStateContext = createContext();

export const useAppState = () => useContext(AppStateContext);

export const AppStateProvider = ({ children }) => {
  const [isPapaVisible, setIsPapaVisible] = useState(false);

  const togglePapaVisibility = () => {
    setIsPapaVisible(prev => !prev);
  };

  return (
    <AppStateContext.Provider value={{ isPapaVisible, togglePapaVisibility }}>
      {children}
    </AppStateContext.Provider>
  );
};