import React, { useState } from 'react';
import './CoreIssueAnalysis.css';

const CoreIssueAnalysis = () => {
  const [selected, setSelected] = useState([]);

  const toggleSelected = (item) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item) ? prevSelected.filter((i) => i !== item) : [...prevSelected, item]
    );
  };

  const [isAceContainerExpanded, setIsAceContainerExpanded] = useState(false);
  const [isFrequencyContainerExpanded, setIsFrequencyContainerExpanded] = useState(false);

  const toggleAceContainer = () => {
    setIsAceContainerExpanded((prevExpanded) => !prevExpanded);
  };

  const toggleFrequencyContainer = () => {
    setIsFrequencyContainerExpanded((prevExpanded) => !prevExpanded);
  };

  const aceData = {
    'ACEDEPRS': 9, 'ACEDRINK': 9, 'ACEDRUGS': 9, 'ACEPRISN': 2, 'ACEDIVRC': 9, 'ACEPUNCH': 2, 'ACEHURT1': 9, 'ACESWEAR': 3, 'ACETOUCH': 9, 'ACETTHEM': 1, 'ACEHVSEX': 1, 'ACEADSAF': 1, 'ACEADNED': 1
  };

  const buttonParagraphPairs = [
    aceData['ACEDEPRS'] === 1
      ? { button: 'DEPR', paragraph: `Do they live with anyone depressed, mentally ill, or suicidal? ${aceData['ACEDEPRS']}` }
      : null,
    aceData['ACEDRINK'] === 1
      ? { button: 'DRINK', paragraph: `Do they live with a problem drinker/alcoholic: ${aceData['ACEDRINK']}` }
      : null,
    aceData['ACEDRUGS'] === 1
      ? { button: 'DRUGS', paragraph: `Do they live with anyone who used illegal drugs or abused prescriptions: ${aceData['ACEDRUGS']}` }
      : null,
    aceData['ACEPRISN'] === 1
      ? { button: 'PRISN', paragraph: `Do they live with anyone who served time in prison or jail: ${aceData['ACEPRISN']}` }
      : null,
    aceData['ACEDIVRC'] === 1
      ? { button: 'DIVRC', paragraph: `Were their parents divorced/seperated: ${aceData['ACEDIVRC']}` }
      : null,
    aceData['ACEPUNCH'] === 2 || aceData['ACEPUNCH'] === 3
      ? { button: 'PUNCH', paragraph: `How often did their parents beat each other up: ${aceData['ACEPUNCH'] === 2 ? 'Once' : 'More than once'}` }
      : null,
    aceData['ACEHURT1'] === 2 || aceData['ACEHURT1'] === 3
      ? { button: 'HURT', paragraph: `How often did a parent physically hurt them in any way: ${aceData['ACEHURT1'] === 2 ? 'Once' : 'More than once'}` }
      : null,
    aceData['ACESWEAR'] === 2 || aceData['ACESWEAR'] === 3
      ? { button: 'SWEAR', paragraph: `How often did a parent swear at them: ${aceData['ACESWEAR'] === 2 ? 'Once' : 'More than once'}` }
      : null,
    aceData['ACETOUCH'] === 2 || aceData['ACETOUCH'] === 3
      ? { button: 'TOUCH', paragraph: `How often did anyone ever touch them sexually: ${aceData['ACETOUCH'] === 2 ? 'Once' : 'More than once'}` }
      : null,
    aceData['ACETTHEM'] === 2 || aceData['ACETTHEM'] === 3
      ? { button: 'TOUCHTHEM', paragraph: `How often did someone made the user touch them sexually: ${aceData['ACETTHEM'] === 2 ? 'Once' : 'More than once'}` }
      : null,
    aceData['ACEHVSEX'] === 2 || aceData['ACEHVSEX'] === 3
      ? { button: 'HVSEX', paragraph: `How often did anyone ever force them to have sex: ${aceData['ACEHVSEX'] === 2 ? 'Once' : 'More than once'}` }
      : null,
    aceData['ACEADSAF'] === 1 || aceData['ACEADSAF'] === 2 || aceData['ACEADSAF'] === 3
      ? { button: 'SAFE', paragraph: `Did an adult make them feel safe and protected: ${aceData['ACEADSAF'] === 1 ? 'Never' : aceData['ACEADSAF'] === 2 ? 'A little of the time' : 'Some of the time'}` }
      : null,
    aceData['ACEADNED'] === 1 || aceData['ACEADNED'] === 2 || aceData['ACEADNED'] === 3
      ? { button: 'ABANDONED', paragraph: `Did an adult make sure basic needs were met: ${aceData['ACEADNED'] === 2 ? 'A little of the time' : aceData['ACEADNED'] === 3 ? 'A little of the time' : 'Some of the time'}` }
      : null,
  ].filter(Boolean);

  return (
    <div className="core-issue-analysis">
      <div className="core-issue-header">
        <img src="Analysis_Icon.png" alt="Issue Analysis" />
        <h4>Core Issue Identification</h4>
      </div>

      <div className="aggregate-ace"> 
        <h3> 5/12 </h3>
        <p> Aggregate ACE </p>
      </div>

      <div className="analysis-container">
        <div className="ace-container">
          <div className="ace-header">
            <img src="dropdownicon.png" alt="ACE Dimensions" onClick={toggleAceContainer} className="dropdown-icon" />
            <p>RELEVANT ACE DIMENSIONS</p>
          </div>
          
          {isAceContainerExpanded && (
            <>
              <div className="buttons-container">
                {buttonParagraphPairs.map((pair) => (
                  <button
                    key={pair.button}
                    className={`button-item ${selected.includes(pair.button) ? 'selected' : ''}`}
                    onClick={() => toggleSelected(pair.button)}
                  >
                    {pair.button}
                  </button>
                ))}
              </div>
              <p>{buttonParagraphPairs.find((pair) => selected.includes(pair.button))?.paragraph}</p>
              <img src="Find_Button.png" className="find-button" alt="Find Button" />
            </>
          )}
        </div>

        <div className="frequency-container">
          <div className="frequency-header">
            <img src="dropdownicon.png" alt="Frequency" onClick={toggleFrequencyContainer} /> {/* Use your dropdown icon image */}
            <p>FREQUENCY</p>
          </div>
          
          {isFrequencyContainerExpanded && (
            <><img src="Word_Group.png" alt="Word Map" className="word-map" /></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoreIssueAnalysis;
