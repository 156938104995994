// InfoPanel.js
import React from 'react';
import './InfoPanel.css';
import CoreIssueAnalysis from './CoreIssueAnalysis';
import SafetyPlan from './SafetyPlan';
import Referrals from './Referrals';
import PanelHeader from './PanelHeader';

function InfoPanel() {
  // Your info panel code here
  return (
    <div className="Panel">
      <PanelHeader />
      <CoreIssueAnalysis />
      <div className="panel-row-2">
        <SafetyPlan />
        <Referrals />
      </div>
    </div>
  );
}

export default InfoPanel;
