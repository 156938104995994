// Referrals.js
import React, { useState } from 'react';
import './Referrals.css';

const Referrals = () => {
  const [selected, setSelected] = useState([]);

  const toggleSelected = (item) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item) ? prevSelected.filter((i) => i !== item) : [...prevSelected, item]
    );
  };

  const [isCopingContainerExpanded, setIsCpoingContainerExpanded] = useState(false);

  const toggleCopingContainer = () => {
    setIsCpoingContainerExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="top-container">
      <div className="header">
        <img src="referrals.png" alt="Safety Plan" />
        <h4>Previous Referrals</h4>
      </div>

      <div className="content-container">
          <div className="referrals-header">
            <img src="referralsdrop.png" alt="ACE Dimensions" onClick={toggleCopingContainer} className="dropdown-icon" />
            <p>CARCROSS SOCIETY</p>
          </div>
          
          {isCopingContainerExpanded && (
            <><p>Culturally safe programs for Indigenous families residing in Carcross YT, addressing loss and grief impacts on children.
            Contact: 867-667-3002. A referral is not required, and support services are provided free of charge. Appointments are available Monday to Friday, from 9am-5pm.</p>
            <img src="Find_Button.png" className="find-button" ></img></>
          )}
      </div>


      {/* ...the remaining component */}
    </div>
  );
};

export default Referrals;
