import React, { useState } from 'react';
import './PAPA.css';

const PAPA = () => {

const [isExpanded, setIsExpanded] = useState(false);

const handleLink = () => {
    window.location.href = 'https://kidshelpphone.ca/get-info/i-am-so-tired-having-friends-perfect-lives/';
};

const handleClick = () => {
    // Toggle expanded state
    setIsExpanded(!isExpanded);
};

const handleContraction = () => {
    setIsExpanded(!isExpanded);
};

const renderMainHeader = () => {
    if (isExpanded) {
        return (
            <>
               <div className="main-title">PAPA</div> 
               <div className="main-buttons"> 
                    <div className="res-button"> KHP Resources </div>
                    <div className="prog-button"> Programs </div>
               </div>
            </>
        );
    }
    return null;
};

const renderSecondCard = () => {
    if (isExpanded) {
        return (
            <>
            <div className="resource-child-expanded">
            <div className="resource-header">
            <div className="resource-icon">KHP</div>
        </div>
        <div className="resource-content">
            <div className="title">Video: What are feelings + how to feel them</div>
            <div className="tags">
                <span className="tag">Coping</span>
                <span className="tag">Self-awareness</span>
                <span className="tag">Tool</span>
            </div>
        </div>
        <img src="cuticon.png" alt="Link icon" className="icon-link" onClick={handleLink} />
        </div>
            </>
        );
    }
    return null;
};

const renderReloadButton = () => {
    if (isExpanded) {
        return (
            <div className="reload">
                <img src="reloadicon.png" alt="Load icon" />
                <span>Load new resources</span>
            </div>
        );
    }
    return null;
}

return (
    <div className="top-resource">
    <div className="drawing-border"></div>
    <div className="resource-card">
        {renderMainHeader()}
        <div className={`resource-child ${isExpanded ? 'expanded' : ''}`}>
        <div className="resource-header">
            {isExpanded ? null : (
                <div className="back-icon" onClick={handleClick}>
                    <img src="back.png" alt="Back icon" />
                </div>
            )}
            <div className="resource-icon">KHP</div>
            <div className="high-impact-match">
                <span>High-impact match</span>
            </div>
        </div>
        <div className="resource-content" onClick={handleContraction}>
            <div className="title">I am so tired of having friends with perfect lives.</div>
            <div className="tags">
                <span className="tag">Depression</span>
                <span className="tag">Friends</span>
                <span className="tag">Sadness</span>
            </div>
            {isExpanded ? null : (
                <p className="summary">
                    This question was submitted to Kids Help Phone by a young person and answered by one of our professional counsellors.
                </p>
            )}
        </div>
        <img src="cuticon.png" alt="Link icon" className="icon-link" onClick={handleLink} /> 
    </div>
    {renderSecondCard()}
    {renderReloadButton()}
    </div>
    </div>
);
};

export default PAPA;