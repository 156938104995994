// TopBar.js
import React, { useEffect, useState } from 'react';
import './TopBar.css';

const TopBar = () => {
  const [name, setName] = useState(null);

  return (
    <div className="top-bar">
      <span>Hi Sarah!</span>
      <div className="logo-container">
        <img src="khp.jpeg" alt="KidsHelpPhone" className="logo" />
      </div>
    </div>
  );
};

export default TopBar;