import React, { useState } from 'react';
import './Ref.css';

const Ref = () => {
    const [clicked, setClicked] = useState(false);

    return (
        <div className="toggle-button">
            <img src='ref-icon.png'></img>
            {clicked && (
                <React.Fragment>
                                        <div className="button-content">
                                            <p style={{ wordWrap: 'break-word' }}> Community Referral: <a href="https://kidshelpphone.ca/resources-around-me/?id=ad424990-a96f-4a70-87ce-ebf517d8a04c_chimo-community-services-peace-program"> PEACE Program @ Chimo Community Services </a> </p>
                                        </div>
                                        <div className="drawing-border"></div>
                                    </React.Fragment>
                                )}
                            </div>
                        );
};

export default Ref;
