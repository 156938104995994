// Sidebar.js
import React from 'react';
import './Sidebar.css';

function Sidebar() {
  // Your sidebar code here
  return (
  <div className="sidebar">
  <img src="peerlogoround.png" alt="Logo" className="peerlogo" />
  <button className="logout-button">Logout</button>
  </div>
  );
};

export default Sidebar;
